import { createRouter, createWebHistory } from 'vue-router';

const routes = [
    {
        path: '/',
        name: 'home',
        meta: { title: 'Home' },
        component: () => import('./components/Home.vue')
    },
    {
        path: '/player',
        name: 'player',
        meta: { title: 'Player' },
        component: () => import('./components/Player.vue')
    },
    {
        path: '/mobs',
        name: 'mobs',
        meta: { title: 'Mobs' },
        component: () => import('./components/Mobs.vue')
    },
    {
        path: '/unlocks',
        name: 'unlocks',
        meta: { title: 'Unlocks' },
        component: () => import('./components/Unlocks.vue')
    },
    {
        path: '/audio',
        name: 'audio',
        meta: { title: 'Audio' },
        component: () => import('./components/Audio.vue')
    },
    {
        path: '/misc',
        name: 'misc',
        meta: { title: 'Misc' },
        component: () => import('./components/Misc.vue')
    },
    {
        path: '/forms',
        name: 'forms',
        meta: { title: 'Forms' },
        component: () => import('./components/Forms.vue')
    },
    {
        path: '/codes',
        name: 'codes',
        meta: { title: 'Codes' },
        component: () => import('./components/Codes.vue')
    },
    {
        path: '/routes',
        name: 'routes',
        meta: { title: 'Routes' },
        component: () => import('./components/Routes.vue')
    },
    {
        path: '/modifiers',
        alias: '/challenge',
        name: 'modifiers',
        meta: { title: 'Challenge Mode' },
        component: () => import('./components/Modifiers.vue')
    },
    {
        path: '/events',
        name: 'events',
        meta: { title: 'Events' },
        component: () => import('./components/Events.vue')
    },
    {
        path: '/stickers',
        name: 'stickers',
        meta: { title: 'Stickers' },
        component: () => import('./components/Stickers.vue')
    },
    {
        path: '/maps',
        name: 'maps',
        meta: { title: 'Maps' },
        component: () => import('./components/Maps.vue')
    },
    {
        path: '/:notFound(.*)',
        name: 'notfound',
        meta: { title: '404' },
        component: () => import('./NotFound.vue')
    },
];

const router = createRouter({
    history: createWebHistory('/'),
    routes,
});

router.beforeEach((to, from, next) => {
    document.title = to.meta.title + " - Dungeon Slasher Information Library";
    next();
});

export default router;
