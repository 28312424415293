<template>
  <div class="flex justify-content-center align-items-center" id="wrapper"
    style="background-image: url('/res/ui/background.png');">
    <div class="flex col-0 lg:col-3" id="desktop-block">
      <img src="/res/ui/DS-logo.webp" id="dslogo" />
    </div>
    <div class="container col-12 lg:col-9 m-0 pl-4 pr-4" id="content-block" :style="contentBlockStyle">
      <div id="content-top"></div>
      <div class="flex justify-content-center mt-4 mb-4">
        <img v-if="isMobile" src="/res/ui/DS-logo.webp" id="dslogo-mobile" />
      </div>
      <nav class="flex justify-content-start" id="controls" style="overflow-x: auto;">
        <router-link :to="'/'" class="router-link-styles">
          <span :style="contentSpanStyle('home')">HOME</span>
        </router-link>
        <router-link :to="'/player'" class="router-link-styles">
          <span :style="contentSpanStyle('player')">PLAYER</span>
        </router-link>
        <router-link :to="'/mobs'" class="router-link-styles">
          <span :style="contentSpanStyle('mobs')">MOBS</span>
        </router-link>
        <router-link :to="'/unlocks'" class="router-link-styles">
          <span :style="contentSpanStyle('unlocks')">UNLOCKS</span>
        </router-link>
        <router-link :to="'/audio'" class="router-link-styles">
          <span :style="contentSpanStyle('audio')">AUDIO</span>
        </router-link>
        <router-link :to="'/misc'" class="router-link-styles">
          <span :style="contentSpanStyle('misc')">MISC</span>
        </router-link>
      </nav>

      <!-- <hr style="border: 1px solid black; margin: 0px;"> -->

      <div class="flex justify-content-center" id="dynamic">
        <div class="mt-5" style="width: 100%;">
          <router-view v-slot="{ Component, route }">
            <transition name="slide-fade" mode="out-in">
              <component :is="Component" :key="route.path" />
            </transition>
          </router-view>
        </div>
      </div>
    </div>
  </div>
  <footer id="footer">
    <img :src="mascotSprite" id="mascot_sprite_gif" @click="mascotInteract" :style="mascotOffset" />
  </footer>
</template>

<script>
import rawMascotData from "../../public/data/mascot.json";

export default {
  data() {
    return {
      haveInteracted: false,
      spriteLeftOffset: 5, // in percent (%)
      spriteBottomOffset: 100, // default
      spriteWidth: 100, // default
      windowWidth: window.innerWidth,
      randSelectedSpriteNo: Math.floor(Math.random() * 5), // Update this value to accomodate more mascots
      mascotSprite: "",
      mascotData: {}
    }
  },

  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },

    mascotInteract() {
      if (!this.haveInteracted) {
        const newSprite = new Image();
        newSprite.src = "res/mascot/" + this.randSelectedSpriteNo + "_2.gif"; // Recommended Height: 900px

        newSprite.onload = () => {
          this.mascotSprite = newSprite.src;
          this.spriteWidth = 185 * this.mascotData.scale;

          const referenceWidth = 1920;
          const scaleFactor = referenceWidth / window.innerWidth;
          const xMoveAmount = scaleFactor * (this.mascotData.xMultiplier * referenceWidth);
          this.spriteLeftOffset -= xMoveAmount;

          const viewportHeight = window.innerHeight;
          const yMoveAmount = viewportHeight * this.mascotData.yMultiplier;

          let animationPeriod = setInterval(() => {
            this.spriteBottomOffset -= yMoveAmount;
          }, 100);

          setTimeout(() => {
            clearInterval(animationPeriod);
          }, 400);

        };
        this.haveInteracted = true;
      }
    },

    contentSpanStyle(content) {
      if (this.$route.name === content) {
        return "background-color: rgba(255, 249, 161, 0.5);";
      } else {
        return "";
      }
    },

    isCurrentPage(content) {
      // Not in use
      return (this.$route.name === content);
    }
  },

  watch: {
    "$route": {
      handler() {
        let element = document.getElementById("controls");
        element.scrollIntoView({
          behavior: 'smooth'
        });
      },
    },
  },

  computed: {
    isMobile() {
      return this.windowWidth < 992;
    },

    mascotOffset() {
      return "left:" + this.spriteLeftOffset + "%; bottom: " + this.spriteBottomOffset + "px; width: " + this.spriteWidth + "px;";
    },

    contentBlockStyle() {
      return this.isMobile ? 'height: 100vh;' : 'height: 80vh;'
    }
  },

  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });

    let mascotIdentifier = "m_" + this.randSelectedSpriteNo;
    this.mascotData = rawMascotData.data[mascotIdentifier];

    this.spriteBottomOffset = this.mascotData.offset;
    this.mascotSprite = "res/mascot/" + this.randSelectedSpriteNo + "_1.gif"; // Recommended Height: 900px
  },

  beforeUnmount() {
    window.removeEventListener("resize", this.onResize);
  },

}
</script>

<style scoped>
.slide-fade-enter-active {
  transition: all 0.7s ease-in-out;
}

.slide-fade-leave-active {
  transition: all 0.7s ease-out;
}

.slide-fade-enter-from {
  transform: translateX(-100px);
  opacity: 0;
}

.slide-fade-leave-to {
  transform: translateX(100px);
  opacity: 0;
}

.fade-enter-active {
  transition: opacity 2s ease;
}

.fade-leave-active {
  transition: opacity 2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

#dslogo {
  position: relative;
  width: 80%;
  left: 10%;
  bottom: 100px;
  object-fit: scale-down;
}

#dslogo-mobile {
  position: relative;
  width: 70%;
}

#wrapper {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 0;
}

#desktop-block {
  margin-right: 0px;
  border-right: 2px solid black;
  height: 80%;
}

#content-block {
  overflow: auto;
}

#dynamic {
  overflow: hidden;
}

#footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 100px;
  background-image: url('../../public/res/ui/footer.png');
  background-repeat: repeat-x;
  background-position-x: center;
  background-position-y: calc(-100%);
  z-index: 4;
  pointer-events: none;
}

#mascot_sprite_gif {
  position: relative;
  transform: scaleX(-1);
  z-index: 4;
  pointer-events: painted;
}

.router-link-styles {
  width: 100%;
  height: 3em;
  padding: 0px 2px;
}

/* .partition {
  border-right: 3px solid white;
} */

span {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  height: 100%;
  width: 100%;
  font-size: 1.6em;
  font-family: serif;
  background:
    linear-gradient(to right, black 2px, transparent 2px) 0 0,
    linear-gradient(to right, black 2px, transparent 2px) 0 100%,
    linear-gradient(to left, black 2px, transparent 2px) 100% 0,
    linear-gradient(to left, black 2px, transparent 2px) 100% 100%,
    linear-gradient(to bottom, black 2px, transparent 2px) 0 0,
    linear-gradient(to bottom, black 2px, transparent 2px) 100% 0,
    linear-gradient(to top, black 2px, transparent 2px) 0 100%,
    linear-gradient(to top, black 2px, transparent 2px) 100% 100%;

  background-repeat: no-repeat;
  background-size: 7px 7px;
  background-color: rgba(255, 255, 255, 0.5);
  padding-left: 7px;
  padding-right: 7px;
}

span:hover {
  font-size: 1.8em;
}

#controls::-webkit-scrollbar {
  display: none;
  /* Hide scrollbar */
}
</style>