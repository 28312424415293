import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';

import './assets/styles/layout.scss';

import router from './router';

import App from './App.vue'

import { createApp } from 'vue';
import { createPinia } from 'pinia'
import PrimeVue from 'primevue/config';

import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';

const pinia = createPinia()
const app = createApp(App);

app.component('Accordion', Accordion);
app.component('AccordionTab', AccordionTab);

app.use(pinia)
app.use(PrimeVue);
app.use(router);

app.mount("#app");
